<template>
  <div class="h-100 dis-flex-com">
    <div v-if="showBack">
      <van-nav-bar :title="formInfo.formname" left-text="返回" left-arrow @click-left="navBarLeft" placeholder fixed>
      </van-nav-bar>
    </div>
    <div v-else>
      <van-nav-bar :title="formInfo.formname" placeholder fixed>
      </van-nav-bar>
    </div>
    <div class="dis-flex-com flx-1 bk-co-f5">
      <img v-if="formInfo.banner != ''" class="w-100" :src="formInfo.banner" alt="">
      <div class="mg-t-10 bk-co-ff">
        <div class="pd-15 dis-flex-row al-c bd-bott ft-sz-16">
          <div class="mg-r-auto dis-flex-row al-c ft-co-fd">
            <span class="qiusofont ft-sz-22">&#xe606;</span>
            <span>说明</span>
          </div>
          <div class="ft-sz-12 ft-co-99">
            {{util.formatDate('',formInfo.createtime)}} -- {{util.formatDate('',formInfo.endtime)}}
          </div>
        </div>
        <div class="pd-15 bd-bott ft-sz-16">
          <span class="pd-x-10"></span>
          {{formInfo.abstract}}
        </div>
      </div>
      <div class="mg-t-10 bk-co-ff">
        <div class="pd-15 bd-bott ft-sz-16">
          <div class="dis-flex-row al-c ft-co-fd">
            <span class="qiusofont ft-sz-22">&#xe606;</span>
            <span>填写信息</span>
          </div>
        </div>
        <van-form @submit="formSubmit">
          <div class="pd-15 bd-bott ft-sz-16" v-for="(item,index) in formList" :key="index">
            <div class="pd-b-10 ft-sz-16" v-if="item.type != 8 && item.type != 3 && item.type != 11">
              <span class="ft-co-fd" v-if="item.isrequire == 1">*</span>{{item.control_name}}
            </div>
            <!-- 单行文本 -->
            <van-field v-if="item.type == 1" v-model="item.val" :placeholder="'请输入'+item.control_name" :rules="[{ required:item.isrequire == 1 }]" />
            <!-- 多行文本 -->
            <van-field v-if="item.type == 2" v-model="item.val" rows="3" autosize type="textarea" maxlength="200" placeholder="请输入内容" show-word-limit :rules="[{ required:item.isrequire == 1 }]" />
            <!-- 下拉选择3 民族选择 11-->
            <div class="dis-flex-row al-c ju-sb" v-if="item.type == 3 || item.type == 11">
              <van-cell :title="item.control_name" />
              <van-dropdown-menu>
                <van-dropdown-item v-model="item.val" :options="arrObj(JSON.parse(item.values))" />
              </van-dropdown-menu>
            </div>
            <!-- 民族选择 -->
            <!-- <div class="dis-flex-row al-c ju-sb" v-if="item.type == 11">
						<van-cell :title="item.control_name"/>
						<van-dropdown-menu >
							<van-dropdown-item v-model="item.val" :options="arrObj(JSON.parse(item.values))" />
						</van-dropdown-menu>
					</div> -->
            <!-- 单选框 -->
            <van-field v-if="item.type == 4" :rules="[{ required:item.isrequire == 1,message: '请选择'+item.control_name }]">
              <template #input>
                <van-radio-group direction="horizontal" v-model="item.val">
                  <van-radio class="mg-r-15" v-for="(items,indexs) in JSON.parse(item.values)" :key="items" :name="items">{{items}}</van-radio>
                </van-radio-group>
              </template>
            </van-field>
            <!-- 复选框 -->
            <van-field v-if="item.type == 5" :rules="[{ required:item.isrequire == 1,message: '请选择'+item.control_name }]">
              <template #input>
                <van-checkbox-group v-model="item.val" direction="horizontal">
                  <van-checkbox class="mg-r-15" v-for="(items,indexs) in JSON.parse(item.values)" :key="items" :name="items" shape="square">{{items}}</van-checkbox>
                </van-checkbox-group>
              </template>
            </van-field>
            <!-- 上传 -->
            <van-uploader v-if="item.type == 6" :after-read="afterRead" v-model="fileList" multiple :max-count="5" :before-read="openClick(index)" />
            <!-- 数值 -->
            <van-field v-if="item.type == 7" v-model="item.val" type="number" :placeholder="'请输入'+item.control_name" :rules="[{ required:item.isrequire == 1 }]" />
            <!-- 日期 -->
            <div class="dis-flex-row al-c" v-if="item.type == 8">
              <van-cell :title="item.control_name" :value="item.val" @click="show1 = true" />
              <van-icon class="mg-l-10" name="arrow" />
              <van-calendar v-model="show1" @confirm="confirmClick" @open="openClick(index)" />
            </div>
            <!-- 手机号 -->
            <van-field v-if="item.type == 10" v-model="item.val" type="tel" :placeholder="'请输入'+item.control_name" :rules="[{ required:item.isrequire == 1}]" />

            <!-- 地址选择 -->
            <van-field v-if="item.type == 12" v-model="item.val" is-link readonly :placeholder="'请输入'+item.control_name" @click="show = true" :rules="[{ required:item.isrequire == 1}]" />
            <van-popup v-if="item.type == 12" v-model="show" round position="bottom">
              <van-cascader title="请选择所在地区" @change="openClick(index)" @finish="onFinish" :options="options" :field-names="{text: 'label', value: 'value', children: 'children'}" @close="show = false" />
            </van-popup>
            <!-- 评分 -->
            <van-rate v-if="item.type == 13" v-model="item.val" class="mg-t-10" color="#ffd21e" void-icon="star" void-color="#eee" />
          </div>
          <div style="margin: 16px;" v-if="curentime < formInfo.endtime && curentime > formInfo.begintime">
            <van-button v-show="isShowSubmit" round block type="info" native-type="submit">提交</van-button>
          </div>
        </van-form>
        <div class="pd-y-15"></div>
      </div>
    </div>
  </div>
</template>

<script>
import http from '../../../lib/api.js'
import util from '../../../lib/util.js'
import axios from 'axios'
import {
  Toast,
  Notify
} from 'vant';
var {
  huoDongJuAcions,
  adminGeneral
} = http
export default {
  name: 'FormDetail',
  components: {},
  data() {
    return {
      showBack: false,
      curentime: 0,
      util: util,
      dateTime: '',
      dateTimeIndex: 0,
      formList: [],
      formInfo: {},
      show: false,
      show1: false,
      options: [],
      currentCity: [],
      fileList: [],
      userData: {},
      companyid: null,
      productid: null,
      isShowSubmit: true,
      user:{}
    }
  },
  created() { },
  mounted() {
    if (this.$route.query.canSubmit) {
      this.isShowSubmit = false;
    }
    if (window.history.state) {
      this.showBack = true;
    }
    if(localStorage.getItem('user')){
			this.user = JSON.parse(localStorage.getItem('user'));
		}
    let datestr = parseInt(Number(new Date()) / 1000)
    this.curentime = datestr;
    this.getFormDetail();
  },
  activated() { },
  methods: {
    navBarLeft() {
      if (window.history.state) {
        this.$router.go(-1)
      } else {
        this.$router.push('/form?companyid'+this.companyid)
      }
    },
    formSubmit(values) { /*提交*/
      var s = this;
      if (this.formInfo.isanonymous == 0) {
        if(localStorage.getItem('user')){
          adminGeneral.userInfo.http({
            "companyid": s.companyid,
            "userid": s.user.userid,
          }).then(res => {
            if (res.getret === 0) {
              s.userData = res.info.user;
              s.submitData();
            }
          })
        } else {
          let miao = 5;
          let interval = setInterval(() => {
            Notify({ type: 'warning', message: '该表单需要实名填写，' + miao + '秒后跳转到登录！', duration: 1000 });
            miao--;
            if (miao == 0) {
              clearInterval(interval);
            }
          }, 1000);
          setTimeout(() => {
            s.$router.push('/login?redirect=' + this.$route.path);
          }, 6000)
          return false;
        }
      } else {
        s.submitData();
      }

    },
    submitData() {
      var info = []
      this.formList.forEach((v, k) => {
        if (v.type == 5 || v.type == 6) {
          info.push({
            formid: this.$route.params.id,
            configid: v.configid,
            value: v.val.join(',')
          })
        } else {
          info.push({
            formid: this.$route.params.id,
            configid: v.configid,
            value: v.val
          })
        }
      })
      let param = {
        info: info,
        formid: this.$route.params.id
      }
      if (this.userData.userid) {
        param.userid = this.userData.userid;
        param.realname = this.userData.realname;
      }
      let s = this;
      huoDongJuAcions.formSubmitSave.http({ info: param }).then(data => {
        if (data.getret === 0) {
          Toast('提交成功');
          setTimeout(function () {
            if (window.history.state) {
              s.$router.go(-1)
            } else {
              s.$router.push('/form?companyid'+s.companyid)
            }
          }, 300)
        } else {
          Toast(data.msg || '提交失败')
        }
      })
    },
    getFormDetail() {
      let s = this;
      axios({
        method: 'get',
        url: '/activity/form/' + this.$route.params.id + '/data.json?t=' + (new Date().getTime() / 1000).toFixed()
      }).then(function (res) {
        if (res.data) {
          s.productid = res.data.productid;
          s.companyid = res.data.companyid;
          s.getCascader();
          s.formInfo = res.data;
          let list = JSON.parse(JSON.stringify(res.data.options));
          list.forEach((v, k) => {
            if (v.type == 3 || v.type == 11) {
              var arr = s.arrObj(JSON.parse(v.values))
            }
            v.val = (v.type == 13 ? null : ((v.type == 5 || v.type == 6) ? [] : ((v.type == 3 || v.type == 11) ? arr[0].value : '')));
          })
          s.formList = list;
        }
      }).catch(e => {
        Toast.fail('获取数据出错！')
      })
      // var s = this;
      // var condition = {
      //   page_index: 0,
      //   page_size: 1,
      //   companyid: s.companyid,
      //   productid: localStorage.getItem('productid'),
      //   formid: s.$route.params.id,
      // };
      // huoDongJuAcions.getFormList.http({ condition }).then(data => {
      //   if (data.getret === 0 && data.list[0]) {
      //     s.formInfo = data.list[0];
      //     let list = JSON.parse(JSON.stringify(data.list[0].list));
      //     list.forEach((v, k) => {
      //       if (v.type == 3 || v.type == 11) {
      //         var arr = s.arrObj(JSON.parse(v.values))
      //       }
      //       v.val = (v.type == 13 ? null : ((v.type == 5 || v.type == 6) ? [] : ((v.type == 3 || v.type == 11) ? arr[0].value : '')));
      //     })
      //     s.formList = list;
      //   }
      // })
    },
    arrObj(val) {
      var arr = []
      val.forEach((v, k) => {
        arr.push({
          text: v,
          value: v
        })
      })
      return arr
    },
    formatDate(date) {
      return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
    },
    confirmClick(e) {
      this.show1 = false;
      this.dateTime = this.formatDate(e);
      this.formList[this.dateTimeIndex].val = this.dateTime;
    },
    openClick(e) {/*当前对象*/
      this.dateTimeIndex = e;
    },
    afterRead(file) {
      file.status = 'uploading';
      file.message = '上传中...';
      var s = this;
      let formData = new FormData();
      formData.append('action', '3001321');
      formData.append('verify', '1072203850');
      formData.append('file', file.file)
      axios.post(s.util.H5API + '?name=handleBeforeUpload', formData)
        .then(function (response) {
          console.log(response)
          if (response.data.getret == 0) {
            file.status = 'success';
            file.message = '上传成功';
            s.formList[s.dateTimeIndex].val.push(s.util.H5API + response.data.info.filepath);
          } else {
            file.status = 'failed';
            file.message = '上传失败';
          }
        })
        .catch(function (error) {
          file.status = 'failed';
          file.message = '上传失败';
          console.log(error);
        });
    },
    getCascader() { /*省*/
      var s = this;
      var condition = {
        "companyid": s.companyid,
        "productid": s.productid,
      }
      huoDongJuAcions.getCascader.http({ condition }).then(data => {
        if (data.getret === 0) {
          s.options = data.list[0].children;
        }
      })
    },
    onFinish({ selectedOptions }) {
      var s = this;
      s.show = false;
      s.formList[s.dateTimeIndex].val = selectedOptions.map((option) => option.label).join('/');
    },
  },
  destroyed() { }
}
</script>
<style scoped>
.van-cell {
  padding: 0 !important;
}
.van-cell::after {
  border: none !important;
}
.van-cell__title {
  font-size: 16px !important;
}
.van-icon {
  color: #969799;
}
button {
  border: none;
  color: #ffffff;
  border-radius: 30px;
  background: -webkit-linear-gradient(#f7514d, #fd704d);
}
button:focus {
  outline: none !important;
}
.van-field__body {
  background-color: #f5f5f5 !important;
}
.van-icon {
  font-size: 22px !important;
}
.van-checkbox-group--horizontal {
  display: block !important;
}
.van-checkbox,
.van-radio {
  align-items: flex-start !important;
}

</style>
<style>
.van-rate__icon {
  font-size: 22px !important;
}
.van-dropdown-menu__bar {
  box-shadow: 0 0 0 #ffffff !important;
}
</style>
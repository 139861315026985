<template>
  <div class="h-100 dis-flex-com">
    <div v-if="showBack">
      <van-nav-bar :title="voteInfo.votetitle" left-text="返回" left-arrow @click-left="navBarLeft" placeholder fixed>
      </van-nav-bar>
    </div>
    <div v-else>
      <van-nav-bar :title="voteInfo.votetitle" placeholder fixed>
      </van-nav-bar>
    </div>
    <div class="dis-flex-com flx-1 bk-co-f5">
      <div class="mg-t-10 bk-co-ff">
        <div class="pd-x-10 pd-t-10 pd-b-10 dis-flex-row al-c bd-bott ft-sz-16">
          <div class="mg-r-auto ov-fl-h" style="-webkit-line-clamp: 1;">{{voteInfo.votetitle}}</div>
        </div>
        <div class="pd-x-10 pd-t-10 pd-b-10 dis-flex-row al-c ft-co-99">
          <div class="mg-r-auto">
            {{voteInfo.totalnumbers}}人参与投票
          </div>
          <div v-if="jisuanTime(voteInfo.endtime)">
            距离结束还有:{{jisuanTime(voteInfo.endtime)}}天
          </div>
        </div>
      </div>
      <div class="mg-t-10 bk-co-ff">
        <div class="pd-x-10 pd-t-10 pd-b-10 dis-flex-row al-c bd-bott ft-sz-16">
          <div>
            <span class="qiusofont ft-sz-22">&#xe606;</span>
          </div>
          <div class="mg-l-5">简介</div>
        </div>
        <div class="pd-x-10 pd-t-10 pd-b-10 ft-sz-14">
          {{voteInfo.abstract}}
        </div>
      </div>
      <div class="mg-t-10 bk-co-ff">
        <div class="pd-x-10 pd-t-10 pd-b-10 dis-flex-row al-c bd-bott ft-sz-16">
          <div class="mg-r-auto">投票项</div>
        </div>
        <div class="pd-15 dis-flex-row ju-sb" style="flex-wrap: wrap;">
          <div class="touPiao mg-b-15 bd-r-5" style="box-shadow: 0 0 5px 0 #999999;" v-for="(item,index) in dataObj.options" :key="'vote'+item.optionsid">
            <div class="pd-y-10">
              <div class="img-w w-100">
                <img class="w-100" :src="item.optionsurl" alt="">
              </div>
              <div class="txt-c pd-t-10 pd-x-5 pd-b-10 ft-sz-16">
                {{item.options}}
              </div>
              <div class="txt-c pd-x-5 pd-b-10">
                <div class="bd-r-30 pd-b-10 pd-t-10 dis-flex-row al-c ft-sz-18 ft-co-fd" style="border: 2px solid #FD482C;">
                  <div class="touP-w po-r">
                    {{item.number}}
                  </div>
                  <van-button :disabled="!isShowSubmit" class="tp-btn" type="info" native-type="button" @click="touPiaoSubimt(item)">投票</van-button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import http from '../../../lib/api.js'
import util from '../../../lib/util.js'
import axios from 'axios'
import {
  Toast,
  Notify
} from 'vant';
var {
  adminGeneral,
  huoDongJuAcions
} = http
export default {
  name: 'VoteDetail',
  components: {},
  data() {
    return {
      showBack: false,
      util: util,
      voteInfo: {
        abstract: '',
        totalnumbers: '',
        endtime: '',
        abstract: '',
        vuid: 0
      },
      dataObj: {},
      userInfo: {},
      companyid: null,
      isShowSubmit: true,
      user:{}
    }
  },
  created() {
    if (this.$route.query.canSubmit) {
      this.isShowSubmit = false;
    }
    if (window.history.state) {
      this.showBack = true;
    }
    if(localStorage.getItem('user')){
      this.user = JSON.parse(localStorage.getItem('user'));
    }
    this.userVoteList();
    this.getDetail();
  },
  mounted() {
  },
  activated() { },
  methods: {
    navBarLeft() {
      if (window.history.state) {
        this.$router.go(-1)
      } else {
        this.$router.push('/vote?companyid'+this.companyid)
      }
    },
    userVoteList() {
      let s = this;
      axios({
        method: 'get',
        url: '/activity/vote/' + this.$route.params.id + '/data.json?t=' + (new Date().getTime() / 1000).toFixed()
      }).then(function (res) {
        if (res.data) {
          s.companyid = res.data.companyid;
          s.voteInfo = res.data;
          if(localStorage.getItem('user')){
            s.getUserInfo();
          }
        }
      }).catch(e => {
        Toast.fail('获取数据出错！')
      })
      // let condition = {
      //   page_index: 0,
      //   page_size: 1,
      //   status: 1,
      //   voteid: s.$route.params.id,
      //   companyid: s.companyid,
      // }
      // huoDongJuAcions.getVoteList.http({
      //   condition
      // }).then(data => {
      //   if (data.getret === 0) {
      //     s.voteInfo = data.list[0];
      //   } else {
      //     Toast.fail('获取数据出错！！')
      //   }
      // })
    },
    getDetail() {/*用户获取投票问题列表(可不登陆) 2001431*/
      var s = this;
      var condition = {
        page_index: 0,
        page_size: 999,
        questionlabe: '',
        voteid: s.$route.params.id
      }
      huoDongJuAcions.getVoteFormList.http({
        condition
      }).then(data => {
        if (data.getret === 0) {
          s.dataObj = data.list[0];
        } else {
          Toast.fail(data.msg || '获取数据出错！！')
        }
      })
    },
    touPiaoSubimt(item) {
      let s = this;
      let params = {};
      let userid = s.user.userid;
      if (s.voteInfo.isrealname != 0) {
        if(localStorage.getItem('user')){
          params = {
            "list": [{
              questionid: item.questionid,
              optionsids: [item.optionsid]
            }],
            "info": {
              voteid: s.voteInfo.voteid,
              vuid: s.voteInfo.vuid,
            },
            "user": {
              userid: userid,
              realname: s.userInfo.realname,
              contact: s.userInfo.contact,
              email: s.userInfo.useremail
            }
          }
        } else {
          let miao = 5;
          let interval = setInterval(() => {
            Notify({ type: 'warning', message: '该投票需要实名投票，' + miao + '秒后跳转到登录！', duration: 1000 });
            miao--;
            if (miao == 0) {
              clearInterval(interval);
            }
          }, 1000);
          setTimeout(() => {
            s.$router.push('/login?redirect=' + this.$route.path);
          }, 6000)
          return false;
        }
      } else {
        params = {
          "list": [{
            questionid: item.questionid,
            optionsids: [item.optionsid]
          }],
          "info": {
            voteid: s.voteInfo.voteid,
            vuid: s.voteInfo.vuid,
          },
          "user": {
            userid: ''
          }
        }
      }
      huoDongJuAcions.voteSubmit.http(params).then(data => {
        if (data.getret === 0) {
          s.getDetail();
          Toast(data.msg);
        } else {
          Toast(data.msg || '提交出错！');
        }
      }).catch(function (error) {
        console.log(error);
      });
    },
    getUserInfo() {
      var s = this;
      adminGeneral.userInfo.http({
        companyid: s.companyid,
        userid: s.user.userid,
      }).then(res => {
        if (res.getret === 0) {
          s.userInfo = res.info.user;
        }else{
          Toast(data.msg || '获取数据失败')
        }
      })
    },
    jisuanTime(val) {
      let s = this;
      if (new Date().getTime() / 1000 > val) return false;
      var data = val - (new Date().getTime() / 1000);
      return Math.floor(data / (24 * 60 * 60))
    },
  },
  destroyed() { }
}
</script>
<style scoped>
.touPiao {
  width: 160px;
  padding: 0 10px;
  box-sizing: border-box;
}
.touPiao .img-w {
  height: 120px;
  overflow: hidden;
}
.touP-w {
  width: 55%;
}
.touP-w::after {
  content: "";
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  border-right: 2px solid #fd482c;
}
.tp-btn {
  background-color: transparent;
  color: #fd482c;
  border: none;
  min-width: 60px;
  padding: 8px;
  height: 30px;
  border-radius: 10px;
  font-size: 18px;
  font-weight: bold;
}
</style>
<style>
.van-rate__icon {
  font-size: 22px !important;
}
.van-dropdown-menu__bar {
  box-shadow: 0 0 0 #ffffff !important;
}
</style>
<template>
  <div class="h-100 dis-flex-com">
    <div v-if="showBack">
      <van-nav-bar :title="activityInfo.partyname" left-text="返回" left-arrow @click-left="navBarLeft" placeholder fixed>
      </van-nav-bar>
    </div>
    <div v-else>
      <van-nav-bar :title="activityInfo.partyname" placeholder fixed>
      </van-nav-bar>
    </div>
    <div class="dis-flex-com flx-1 bk-co-f5">
      <div class="mg-t-10 bk-co-ff">
        <div class="dis-flex-row ft-co-ff">
          <div class="mg-l-auto dis-flex-row">
            <div class="po-r" style="top: 4px;">
              <span class="pd-x-10 ft-sz-10 bk-co-fd4 pd-t-5 pd-b-5" style="border-bottom-left-radius: 20px;">
                {{util.formatDate('yy-MM-dd',activityInfo.begintime)}}
              </span>
            </div>
            <div class="bk-co-fd pd-x-10 pd-t-5 pd-b-5 ft-sz-14" style="border-bottom-left-radius: 20px;">
              活动时间
            </div>
          </div>
        </div>
        <div class="pd-x-10 pd-t-10 pd-b-10 dis-flex-row al-c bd-bott ft-sz-16 ov-fl-h" style="-webkit-line-clamp: 1;">
          {{activityInfo.partyname}}
        </div>
        <div class="pd-x-10 pd-t-10 pd-b-10 dis-flex-row">
          <div class="bk-co-f5 flx-1 pd-x-10 pd-t-10 pd-b-10 bd-r-5 ft-sz-14" style="color: #5f5f5f;" v-html="activityInfo.abstract"></div>
        </div>
        <div class="pd-x-10 pd-b-10 dis-flex-row al-c ft-sz-18 ft-co-fd">
          <span class="mg-r-10">参加人数:</span><span>{{dataObj.totalnumber}}</span>
        </div>
      </div>
      <div class="mg-t-10 bk-co-ff">
        <div class="pd-x-10 pd-t-10 pd-b-10 bd-bott ft-sz-16">
          填写信息
        </div>
        <div class="">
          <van-field v-model="info.uname" :disabled="reportstatus" label="姓名" />
          <van-field v-model="info.mobile" :disabled="reportstatus" type="tel" label="手机号" />
          <van-field v-model="info.number" :disabled="reportstatus" type="digit" label="报名人数" />
          <div class="pd-x-15 pd-t-10 pd-b-10 bd-bott ft-sz-16">
            备注:
          </div>
          <div class="bd-bott">
            <van-field v-model="info.remarks" rows="4" autosize :disabled="reportstatus" type="textarea" maxlength="200" placeholder="请输入备注内容" show-word-limit />
          </div>
        </div>
        <div v-if="!reportstatus">
          <div class="pd-x-10">
            <div class="mg-t-30">
              <button v-show="isShowSubmit" class="pd-y-10 w-100 ft-co-ff ft-sz-16" type="button" @click="huoDongSubmint">提交</button>
            </div>
          </div>
          <div class="pd-y-15"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import http from '../../../lib/api.js'
import util from '../../../lib/util.js'
import axios from 'axios'
import {
  Toast,
  Notify
} from 'vant';
var {
  adminGeneral,
  huoDongJuAcions
} = http
export default {
  name: 'ActivityDetail',
  components: {},
  data() {
    return {
      showBack:false,
      title:'',
      util: util,
      info: {/*活动*/
        uname: '',
        mobile: '',
        number: 1,
        remarks: ''
      },
      activityInfo: {
        begintime: ''
      },
      dataObj: {
        totalnumber: 0
      },
      reportstatus: false,
      companyid: null,
      productid: '1028401310',
      isShowSubmit: true,
      user:{}
    }
  },
  created() {
    if (this.$route.query.canSubmit) {
      this.isShowSubmit = false;
    }
    if(localStorage.getItem('user')){
      this.user = JSON.parse(localStorage.getItem('user'));
    }
    this.getDetail();
    if(window.history.state){
      this.showBack = true;
    }
  },
  mounted() {
  },
  methods: {
    navBarLeft(){
      if (window.history.state) {
        this.$router.go(-1)
      } else {
        this.$router.push('/activity?companyid'+this.companyid)
      }
    },
    getDetail() {
      var s = this;
      axios({
        method: 'get',
        url: '/activity/party/' + this.$route.params.id + '/data.json?t=' + (new Date().getTime() / 1000).toFixed()
      }).then(function (res) {
        if (res.data) {
          s.companyid = res.data.companyid;
          s.productid = res.data.productid;
          s.activityInfo = res.data;
          let condition = {
            "companyid": s.companyid,
            "productid": s.productid,
            "partyid": s.$route.params.id
          }
          huoDongJuAcions.getActivityDetail.http({
            condition
          }).then(data => {
            if (data.getret === 0) {
              s.dataObj = data;
            }
          })
          if(localStorage.getItem('user')){
            s.getUserInfo();
            s.getActivitusereport();
          }
        }
      }).catch(e => {
        Toast.fail('获取数据出错！')
      })
    },
    getActivitusereport() {
      var s = this;
      var condition = {
        "companyid": s.companyid,
        "productid": s.productid,
        "partyid": s.$route.params.id
      }
      huoDongJuAcions.getActivitusereport.http({
        condition
      }).then(data => {
        if (data.getret == 0 && data.isjoin == true) {
          s.reportstatus = true;
          s.info.number = data.info.number;
          s.info.uname = data.info.username;
          s.info.mobile = data.info.mobile;
          s.info.remarks = data.info.remarks;
        }
      })
    },
    huoDongSubmint() {
      var s = this;
      if (s.activityInfo.isanonymous == 0) { //1:允许匿名，0：不允许
        if(localStorage.getItem('user')){
          s.submitData();
        } else {
          let miao = 5;
          let interval = setInterval(() => {
            Notify({ type: 'warning', message: '该活动需要实名参加，' + miao + '秒后跳转到登录！', duration: 1000 });
            miao--;
            if (miao == 0) {
              clearInterval(interval);
            }
          }, 1000);
          setTimeout(() => {
            s.$router.push('/login?redirect=' + this.$route.path);
          }, 6000)
          return false;
        }
      } else {
        s.submitData();
      }
    },
    submitData() {
      let s = this;
      if (s.info.mobile == '') return Toast('手机号码不能为空');
      if (!/^[1][0-9]{10}$/.test(s.info.mobile)) return Toast('请输入合法的手机号');
      if (s.info.number < 0) return Toast('报名人数不能小于0人')
      var { info } = s;
      info = Object.assign(info, {
        "companyid": s.companyid,
        "productid": s.productid,
        "partyid": s.$route.params.id
      })
      huoDongJuAcions.activityFormSubmit.http({
        info
      }).then(data => {
        if (data.getret === 0) {
          Toast('提交成功')
          setTimeout(function () {
            if (window.history.state) {
              s.$router.go(-1)
            } else {
              s.$router.push('/activity?companyid'+s.companyid)
            }
          }, 1000)
          // if(s.$route.params.type == 1){
          // }else{
          // 	setTimeout(function(){
          // 		s.$router.push('/');
          // 	},300)
          // }
        } else {
          Toast(data.msg || '提交失败')
        }
      })
    },
    getUserInfo() {
      var s = this;
      adminGeneral.userInfo.http({
        companyid: s.companyid,
        userid: s.user.userid,
      }).then(res => {
        if (res.getret === 0) {
          s.info.uname = res.info.user.realname
          s.info.mobile = res.info.user.usermobile
        }else{
          Toast(data.msg || '获取数据失败')
        }
      })
    },
  },
  destroyed() { }
}
</script>
<style scoped>
button {
  border: none;
  color: #ffffff;
  border-radius: 30px;
  background: -webkit-linear-gradient(#f7514d, #fd704d);
}
button:focus {
  outline: none !important;
}
.van-field__body {
  background-color: #f5f5f5 !important;
}
.van-checkbox-group--horizontal {
  display: block !important;
}
.van-checkbox,
.van-radio {
  align-items: flex-start !important;
}

</style>
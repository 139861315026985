<template>
  <div class="h-100 dis-flex-com">
    <div v-if="searchStatus">
      <van-nav-bar title="调查" left-text="返回" left-arrow @click-left="navBarLeft" @click-right="navBarRight" placeholder fixed>
        <template #right>
          <van-icon name="search" />
        </template>
      </van-nav-bar>
    </div>
    <div class="bd-bott" v-if="!searchStatus">
      <form action="/">
        <van-search v-model="condition.inquiretitle" input-align="center" show-action placeholder="请输入搜索关键词" @input="onSearch" @cancel="onCancel" />
      </form>
    </div>
    <div class="flx-1 bk-co-f5" ref="evabox" @scroll="Scroll" style="overflow-y: auto;">
      <div class="mg-t-10 bk-co-ff" v-for="(item,index) in dataList" :key="item.inquireid" @click="Details(item)">
        <div class="pd-x-10 pd-t-10 pd-b-10 dis-flex-row al-c bd-bott ft-sz-16">
          <div class="mg-r-auto ov-fl-h" style="-webkit-line-clamp: 1;">{{item.inquiretitle}}</div>
          <div>
            <van-icon name="arrow" />
          </div>
        </div>
        <div class="pd-x-10 pd-t-10 pd-b-10 dis-flex-row al-c ft-co-99 po-r">
          <span v-if="curentime < item.begintime" class="qiusofont po-a statusIcon" style="color:blue;">&#xe6c4;</span>
          <span v-else-if="curentime > item.endtime" class="qiusofont po-a statusIcon">&#xe6bf;</span>
          <span v-else-if="curentime < item.endtime && curentime > item.begintime" class="qiusofont po-a statusIcon" style="color:blue;">&#xe6c0;</span>
          <span v-else class="qiusofont po-a statusIcon">&#xe6c1;</span>
          <div class="mg-r-auto">
            问卷编号:{{item.inquireid}}
          </div>
          <div>
            发布时间:{{util.formatDate('',item.createtime)}}
          </div>
        </div>
      </div>
      <div class="txt-c ft-co-99 ft-sz-16 pd-y-10" v-if="flag && !loading">
        没有更多数据了
      </div>
    </div>
  </div>
</template>
<script>
import http from '../../lib/api.js'
import util from '../../lib/util.js'
import {
  Toast
} from 'vant';
var {
  huoDongJuAcions
} = http
export default {
  name: 'Survey',
  components: {},
  data() {
    return {
      util: util,
      searchStatus: true,/*搜素框显示*/
      dataList: [],
      condition: {
        page_index: 0,
        page_size: 10,
        inquireid: "",
        inquiretitle: "",
        type: 0
      },
      flag: false,
      loading: false,
      total: 0,
      curentime: 0,
      companyid:null
    }
  },
  created() { },
  mounted() {
    if(this.$route.query.companyid){
      this.companyid = this.$route.query.companyid;
      let datestr = parseInt(Number(new Date()) / 1000)
      this.curentime = datestr;
      this.getSurveyList();
    }else{
      Toast.fail('地址参数有误！')
    }
  },
  activated() { },
  methods: {
    getSurveyList() {/*获取调查列表（不需要登录） 2001530*/
      var s = this;
      var { condition } = s;
      condition = Object.assign(condition, {
        "companyid": s.companyid
      })
      huoDongJuAcions.getSurveyList.http({
        condition
      }).then(data => {
        if (data.getret === 0) {
          if (data.total > 0) {
            if (data.condition.page_index > '0') {
              s.dataList = s.dataList.concat(data.list)
            } else {
              s.dataList = data.list;
            }
            s.total = data.total
          }else{
            s.dataList = [];
            s.total = 0;
          }
        }else{
					Toast.fail('获取数据出错！！')
				}
      });
    },
    onSearch(val) {/*搜索*/
      this.getSurveyList();
    },
    onCancel() {/*取消*/
      this.searchStatus = true;
    },
    navBarLeft() {
      this.$router.go(-1)
    },
    navBarRight() {
      this.searchStatus = false;
    },
    Details(val) {
      var s = this;
      this.$router.push('/survey/detail/' + val.inquireid)
    },
    Scroll(e) {
      let s = this;
      const ele = e.srcElement ? e.srcElement : e.target
      // 监听滚动到div底部
      if (ele.scrollTop + ele.offsetHeight > ele.scrollHeight - 100) {
        if (s.flag === false) {//避免多次请求 加锁 该次请求完成 才能再次发起请求
          s.flag = true
          if (s.total > s.dataList.length) {
            s.condition.page_index++
            s.getSurveyList();
          }
        }
      }
    }
  },
  destroyed() { }
}
</script>
<style scoped>
.van-icon {
  font-size: 18px !important;
}
</style>
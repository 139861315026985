<template>
  <div class="h-100 dis-flex-com">
    <div v-if="showBack">
      <van-nav-bar :title="dataList.inquiretitle" left-text="返回" left-arrow @click-left="navBarLeft" placeholder fixed>
      </van-nav-bar>
    </div>
    <div v-else>
      <van-nav-bar :title="dataList.inquiretitle" placeholder fixed>
      </van-nav-bar>
    </div>
    <div class="dis-flex-com flx-1 bk-co-f5">
    <div class="mg-t-10 bk-co-ff">
      <div class="pd-x-10 pd-t-10 pd-b-10 dis-flex-row al-c bd-bott ft-sz-16">
        <div class="mg-r-auto ov-fl-h" style="-webkit-line-clamp: 1;">{{dataList.inquiretitle}}</div>
      </div>
      <div class="pd-x-10 pd-t-10 pd-b-10 dis-flex-row al-c ft-co-99">
        <div class="mg-r-auto">
          问卷编号:{{dataList.inquireid}}
        </div>
        <div>
          发布时间:{{util.formatDate('',dataList.createtime)}}
        </div>
      </div>
    </div>
    <div class="mg-t-10 bk-co-ff">
      <div class="pd-x-10 pd-t-10 pd-b-10 dis-flex-row al-c bd-bott ft-sz-16">
        <div>
          <span class="qiusofont ft-sz-22">&#xe606;</span>
        </div>
        <div class="mg-l-5">简介</div>
      </div>
      <div class="pd-x-10 pd-t-10 pd-b-10 ft-sz-14">
        <span class="pd-x-10"></span> {{dataList.abstract}}
      </div>
    </div>
    <div class="mg-t-10 bk-co-ff" v-for="(item,index) in evaluationStarList" :key="index">
      <div class="pd-10 bd-bott ft-sz-16">
        <span class="ft-co-fd" v-if="item.questionrequire == 1">*</span>{{item.questionlabe}}
      </div>
      <div class="bd-bott pd-15" v-if="item.questionurl != ''">
        <img height="200" :src="item.questionurl" alt="">
      </div>
      <div class="pd-x-10 pd-t-10 pd-b-10 ft-sz-14" v-if="item.questionstyle == 'star'">
        <span class="mg-r-10" v-for="(items,indexs) in item.options" :key="indexs"><van-icon name="star" :color="item.inxsStatr >= indexs ?'#f5e207':'#999999'" @click="starClick(index,indexs)" /></span>
      </div>
      <div class="pd-x-10 pd-t-10 pd-b-10 ft-sz-14" v-if="item.questionstyle == 'score'">
        <span class="mg-r-10" v-for="(items,indexs) in item.options" :key="indexs"><van-icon name="good-job" :color="item.inxsStatr >= indexs ?'#f52944':'#999999'" @click="starClick(index,indexs)" /></span>
      </div>
      <div class="pd-x-10 pd-b-10 ft-sz-14" v-if="item.questionstyle == 'number'">
        <div class="pd-b-10 pd-x-10 pd-t-10 mg-t-10 ft-sz-16 bd-r-5" :class="item.inxsStatr == indexs ?'bk-co-fd ft-co-ff':''" v-for="(items,indexs) in item.options" :key="indexs" @click="starClick(index,indexs)" style="box-shadow: 0 2px 5px 0 #d1d1d1;">
          {{items.options}}
        </div>
      </div>
    </div>
    <div class="mg-t-10 bk-co-ff">
      <div class="pd-x-10 pd-t-10 pd-b-10 bd-bott ft-sz-16">
        填写信息
      </div>
      <div class="">
        <van-field v-model="evaluationStarFrom.realname" label="姓名" />
        <van-field v-model="evaluationStarFrom.contact" type="tel" label="手机号" />
        <van-field v-model="evaluationStarFrom.dataTime" label="提交时间" disabled />
      </div>
      <div class="pd-x-10">
        <div class="mg-t-30">
          <button v-show="isShowSubmit" class="pd-y-10 w-100 ft-co-ff ft-sz-16" :disabled='!canSubmit' type="button" @click="starSubmit">提交</button>
        </div>
      </div>
      <div class="pd-y-15"></div>
    </div>
  </div>
  </div>
</template>

<script>
import http from '../../../lib/api.js'
import util from '../../../lib/util.js'
import axios from 'axios'
import {
  Toast
} from 'vant';
var {
  huoDongJuAcions,
  adminGeneral
} = http
export default {
  name: 'EvaluationStarDetail',
  components: {},
  data() {
    return {
      showBack:false,
      canSubmit: true,
      util: util,
      dataList: {},
      evaluationStarList: [],
      evaluationStarFrom: {
        realname: '',
        contact: '',
        dataTime: ''
      },
      companyid: null,
      isShowSubmit: true,
      user:{}
    }
  },
  created() {
    if (this.$route.query.canSubmit) {
      this.isShowSubmit = false;
    }
    if(window.history.state){
      this.showBack = true;
    }
    if(localStorage.getItem('user')){
      this.user = JSON.parse(localStorage.getItem('user'));
    }
    let chuo = new Date()
    let year = chuo.getFullYear();
    let month = chuo.getMonth() + 1;
    let day = chuo.getDate();
    this.evaluationStarFrom.dataTime = year + "-" + month + "-" + day;
    // this.getList();
    this.getDetail();
  },
  mounted() {
  },
  activated() { },
  methods: {
    navBarLeft(){
      if (window.history.state) {
        this.$router.go(-1)
      } else {
        this.$router.push('/evaluationStar?companyid'+this.companyid)
      }
    },
    // getList() {/*用户获取投票列表 2001530*/
    //   var s = this;
    //   var condition = {
    //     page_index: 0,
    //     page_size: 1,
    //     companyid: s.companyid,
    //     productid: localStorage.getItem('productid'),
    //     inquireid: s.$route.params.id
    //   }
    //   huoDongJuAcions.getSurveyList.http({
    //     condition
    //   }).then(data => {
    //     if (data.getret === 0) {
    //       s.dataList = data.list[0];
    //     } else {
    //       Toast('获取数据出错！');
    //     }
    //   });
    // },
    getDetail() {
      var s = this;
      axios({
        method: 'get',
        url: '/activity/rate/' + this.$route.params.id + '/data.json?t=' + (new Date().getTime() / 1000).toFixed()
      }).then(function (res) {
        if (res.data) {
          s.companyid = res.data.companyid;
          s.dataList = res.data;
          let list = JSON.parse(JSON.stringify(res.data.options));
          list.forEach((v, k) => {
            v.optionsids = [];
            v.othertext = '';
            v.inxsStatr = -1;
            v.options = v.options.sort(s.compare('optionsext'))
          })
          s.evaluationStarList = list;
          if(localStorage.getItem('user')){
            s.getUserInfo();
          }
        }
      }).catch(e => {
        Toast.fail('获取数据出错！')
      })
      // var condition = {
      //   page_index: 0,
      //   page_size: 10000,
      //   inquireid: s.$route.params.id
      // }
      // huoDongJuAcions.getSurveyDetail.http({
      //   condition
      // }).then(data => {
      //   if (data.getret === 0) {
      //     data.list.forEach((v, k) => {
      //       v.optionsids = [];
      //       v.othertext = '';
      //       v.inxsStatr = -1;
      //       v.options = v.options.sort(s.compare('optionsext'))
      //     })
      //     s.evaluationStarList = data.list;
      //   }
      // })
    },
    starSubmit() {/*提交*/
      let s = this;
      this.canSubmit = false;
      let list = []
      s.evaluationStarList.forEach((v, k) => {
        let arr;
        if (typeof (v.optionsids) == 'object') {
          arr = v.optionsids
        } else {
          arr = [v.optionsids]
        }
        list.push({
          "questionid": v.questionid,
          "optionsids": arr,
          "othertext": v.othertext
        })
      })
      let params = {};
      let userid = s.user.userid;
      if (s.dataList.isrealname != 0) {
        if(localStorage.getItem('user')){
          params = {
            "info": {
              "inquireid": s.$route.params.id,
              "vuid": s.dataList.vuid + '',
            },
            "list": list,
            "user": {
              realname: s.evaluationStarFrom.realname,
              contact: s.evaluationStarFrom.contact,
              userid: userid
            }
          }
        } else {
          let miao = 5;
          let interval = setInterval(() => {
            Notify({ type: 'warning', message: '该评星需要实名填写，' + miao + '秒后跳转到登录！', duration: 1000 });
            miao--;
            if (miao == 0) {
              clearInterval(interval);
            }
          }, 1000);
          setTimeout(() => {
            s.$router.push('/login?redirect=' + this.$route.path);
          }, 6000)
          return false;
        }
      } else {
        params = {
          "info": {
            "vuid": s.dataList.vuid + '',
            "inquireid": s.$route.params.id
          },
          "list": list,
          "user": {
            realname: s.evaluationStarFrom.realname,
            contact: s.evaluationStarFrom.contact,
            userid: userid
          }
        }
      }
      huoDongJuAcions.surveySubmit.http(params).then(data => {
        s.canSubmit = true;
        if (data.getret === 0) {
          Toast(data.msg);
          setTimeout(function () {
            if (window.history.state) {
              s.$router.go(-1)
            } else {
              s.$router.push('/evaluationStar?companyid'+s.companyid)
            }
          }, 1000)
        } else {
          Toast.fail(data.msg || '提交出错！');
        }
      }).catch(function (error) {
        console.log(error);
        s.canSubmit = true;
      });
    },
    getUserInfo() {
      var s = this;
      adminGeneral.userInfo.http({
        companyid: s.companyid,
        userid: s.user.userid,
      }).then(res => {
        if (res.getret === 0) {
          s.evaluationStarFrom.contact = res.info.user.usermobile
          s.evaluationStarFrom.realname = res.info.user.realname
        }else{
          Toast(data.msg || '获取数据失败')
        }
      })
    },
    starClick(inx, inxs) {
      let s = this;
      // console.log(s.evaluationStarList[inx].options[inxs].optionsext)
      s.evaluationStarList[inx].inxsStatr = inxs;
      s.evaluationStarList[inx].optionsids = s.evaluationStarList[inx].options[inxs].optionsid
      s.evaluationStarList[inx].othertext = s.evaluationStarList[inx].options[inxs].optionsext
    },
    compare(property) {
      return function (a, b) {
        var value1 = a[property];
        var value2 = b[property];
        return value1 - value2;
      }
    }
  },
  destroyed() { }
}
</script>
<style scoped>
button {
  border: none;
  color: #ffffff;
  border-radius: 30px;
  background: -webkit-linear-gradient(#f7514d, #fd704d);
}
button:focus {
  outline: none !important;
}
.van-field__body {
  background-color: #f5f5f5 !important;
}
.van-icon {
  font-size: 22px !important;
}
.van-checkbox-group--horizontal {
  display: block !important;
}
.van-checkbox,
.van-radio {
  align-items: flex-start !important;
}
</style>
<template>
  <div class="h-100 dis-flex-com">
    <div v-if="showBack">
      <van-nav-bar :title="survey.inquiretitle" left-text="返回" left-arrow @click-left="navBarLeft" placeholder fixed>
      </van-nav-bar>
    </div>
    <div v-else>
      <van-nav-bar :title="survey.inquiretitle" placeholder fixed>
      </van-nav-bar>
    </div>
    <div class="dis-flex-com flx-1 bk-co-f5">
      <div class="mg-t-10 bk-co-ff">
        <div class="pd-x-10 pd-t-10 pd-b-10 dis-flex-row al-c bd-bott ft-sz-16">
          <div class="mg-r-auto ov-fl-h" style="-webkit-line-clamp: 1;">{{survey.inquiretitle}}</div>
        </div>
        <div class="pd-x-10 pd-t-10 pd-b-10 dis-flex-row al-c ft-co-99 po-r">
          <!-- 待开始 -->
          <span v-if="curentime < survey.begintime" class="qiusofont po-a statusIcon" style="color:blue;">&#xe6c4;</span>
          <!-- 进行中 -->
          <span v-else-if="curentime<=survey.endtime && vuidstatus==false" class="qiusofont po-a statusIcon" style="color:blue;">&#xe6c0;</span>
          <!-- 已完成 -->
          <span v-else-if="curentime<=survey.endtime && vuidstatus==true" class="qiusofont po-a statusIcon">&#xe6c1;</span>
          <!-- 已结束 -->
          <span v-else-if="vuidstatus==true || curentime>survey.endtime" class="qiusofont po-a statusIcon">&#xe6bf;</span>
          <div class="mg-r-auto">
            问卷编号:{{survey.inquireid}}
          </div>
          <div>
            发布时间:{{util.formatDate('',survey.createtime)}}
          </div>
        </div>
      </div>
      <div class="mg-t-10 bk-co-ff">
        <div class="pd-x-10 pd-t-10 pd-b-10 dis-flex-row al-c bd-bott ft-sz-16">
          <div>
            <span class="qiusofont ft-sz-22">&#xe606;</span>
          </div>
          <div class="mg-l-5">简介</div>
        </div>
        <div class="pd-x-10 pd-t-10 pd-b-10 ft-sz-14">
          <span class="pd-x-10"></span> {{survey.abstract}}
        </div>
        <div class="mg-t-10 bk-co-ff" v-for="(item,index) in questionList" :key="index">
          <div class="pd-10 bd-bott ft-sz-16">
            <span class="ft-co-fd" v-if="item.questionrequire == 1">*</span>{{item.questionlabe}} <span class="ft-co-99">{{item.questiontype == '0'?'（单选）':item.questiontype == '1'?'（多选）':''}}</span>
          </div>
          <div class="bd-bott pd-15" v-if="item.questionurl != ''">
            <img height="200" :src="item.questionurl" alt="">
          </div>
          <div class="pd-x-10 pd-t-10 pd-b-10 ft-sz-14" v-if="item.questiontype == '0'">
            <van-radio-group v-model="item.optionsids">
              <div class="pd-b-10 pd-x-10 pd-t-10 mg-t-10 ft-sz-16 bd-r-5" v-for="(items,indexs) in item.options" :key="indexs" style="box-shadow: 0 2px 5px 0 #d1d1d1;">
                <van-radio :name="items.optionsid" :disabled="vuidstatus">
                  <div>
                    {{items.options}}
                  </div>
                  <div class="pd-t-10" v-if="items.optionsurl">
                    <van-image width="3rem" height="3rem" fit="cover" :src="items.optionsurl" />
                  </div>
                </van-radio>
              </div>
            </van-radio-group>
          </div>
          <div class="pd-x-10 pd-t-10 pd-b-10 ft-sz-14" v-if="item.questiontype == '1'">
            <van-checkbox-group v-model="item.optionsids" direction="horizontal">
              <div class="pd-b-10 pd-x-10 pd-t-10 mg-t-10 ft-sz-16 bd-r-5" v-for="(items,indexs) in item.options" :key="indexs" style="box-shadow: 0 2px 5px 0 #d1d1d1;">
                <van-checkbox :name="items.optionsid" shape="square" :disabled="vuidstatus">
                  <div>
                    {{items.options}}
                  </div>
                  <div class="pd-t-10" v-if="items.optionsurl">
                    <van-image width="3rem" height="3rem" fit="cover" :src="items.optionsurl" />
                  </div>
                </van-checkbox>
              </div>
            </van-checkbox-group>
          </div>
          <div class="pd-x-10 pd-t-10 pd-b-10 ft-sz-14" v-if="item.questiontype == '2'">
            <van-field v-model="item.othertext" :disabled="vuidstatus" placeholder="请输入内容" />
          </div>
          <div class="pd-x-10 pd-t-10 pd-b-10 ft-sz-14" v-if="item.questiontype == '3'">
            <van-field v-model="item.othertext" :disabled="vuidstatus" rows="3" autosize type="textarea" maxlength="200" placeholder="请输入内容" show-word-limit />
          </div>
        </div>
        <div class="pd-x-10" v-if="!vuidstatus">
          <div class="mg-t-30" v-show="isShowSubmit">
            <button class="pd-y-10 w-100 ft-co-ff ft-sz-16" type="button" :disabled='!canSubmit' @click="surveySubmit">提交</button>
          </div>
        </div>
        <div class="pd-y-15"></div>
      </div>
    </div>
  </div>
</template>

<script>
import http from '../../../lib/api.js'
import util from '../../../lib/util.js'
import axios from 'axios'
import {
  Toast
} from 'vant';
var {
  huoDongJuAcions
} = http
export default {
  name: 'SurveyDetail',
  components: {},
  data() {
    return {
      showBack: false,
      canSubmit: true,
      util: util,
      survey: {},
      questionList: [],
      // statucode: true,/*开始/未开始*/
      vuidstatus: false,/*禁止输入*/
      curentime: 0,
      vuid: 0,
      isShowSubmit: true,
      companyid: null,
      productid: null,
      user:{}
    }
  },
  created() {
    if (this.$route.query.canSubmit) {
      this.isShowSubmit = false;
    }
    if (window.history.state) {
      this.showBack = true;
    }
    if(localStorage.getItem('user')){
			this.user = JSON.parse(localStorage.getItem('user'));
		}
    let datestr = parseInt(Number(new Date()) / 1000)
    this.curentime = datestr;
    this.getDetailData();
    // this.getSurveyList();
    // this.getSurveyData();
  },
  mounted() {
  },
  activated() { },
  methods: {
    navBarLeft() {
      if (window.history.state) {
        this.$router.go(-1)
      } else {
        this.$router.push('/survey?companyid'+this.companyid)
      }
    },
    getDetailData() {
      let s = this;
      axios({
        method: 'get',
        url: '/activity/inquire/' + this.$route.params.id + '/data.json?t=' + (new Date().getTime() / 1000).toFixed()
      }).then(function (res) {
        if (res.data) {
          s.productid = res.data.productid;
          s.companyid = res.data.companyid;
          s.survey = res.data;
          let list = JSON.parse(JSON.stringify(res.data.options));
          list.forEach((v, k) => {
            v.optionsids = [];
            v.othertext = '';
            v.inxsStatr = -1;
            v.options = v.options.sort(s.compare('optionsext'))
          })
          s.questionList = list;
          if (s.curentime < s.survey.begintime || s.curentime > s.survey.endtime) {
            s.vuidstatus = true;
          }
          //获取是否已提交调查状态
          if (s.user.userid) {
            s.getformstatus();
          }
        }
      }).catch(e => {
        Toast.fail('获取数据出错！')
      })
    },
    // getSurveyList() {/*获取调查列表（不需要登录） 2001530*/
    //   var s = this;
    //   var condition = {
    //     page_index: 0,
    //     page_size: 1,
    //     companyid: s.companyid,
    //     // productid: localStorage.getItem('productid'),
    //     inquireid: s.$route.params.id
    //   }
    //   huoDongJuAcions.getSurveyList.http({
    //     condition
    //   }).then(data => {
    //     if (data.getret === 0) {
    //       s.survey = data.list[0];
    //     } else {
    //       Toast('获取数据出错！');
    //     }
    //   });
    // },
    // getSurveyData() {/*获取调查详情（不需要登录） 2001531*/
    //   var s = this;
    //   var condition = {
    //     page_index: 0,
    //     page_size: 10000,
    //     inquireid: s.$route.params.id
    //   }
    //   huoDongJuAcions.getSurveyDetail.http({
    //     condition
    //   }).then(data => {
    //     if (data.getret === 0) {
    //       data.list.forEach((v, k) => {
    //         v.optionsids = [];
    //         v.othertext = '';
    //         v.inxsStatr = -1;
    //         v.options = v.options.sort(s.compare('optionsext'))
    //         // v.options.forEach((l,j)=>{
    //         // 	l.optionsid = l.optionsid + ''
    //         // })
    //       })
    //       s.questionList = data.list;
    //       console.log(s.user.userid)
    //       //获取是否已提交调查状态
    //       if (s.user.userid) {
    //         s.getformstatus();
    //       }
    //     } else if (data.getret == 11) {
    //       /*未开始*/
    //       s.statucode = false;
    //     } else if (data.getret == 12) {
    //       /*结束*/
    //       s.vuidstatus = true;
    //     } else if (data.getret == 1000) {//必须登录时返回首页
    //       s.$router.push('/login')
    //     }
    //   })
    // },
    surveySubmit() {/*提交*/
      this.canSubmit = false;
      let s = this;
      let list = []
      s.questionList.forEach((v, k) => {
        let arr;
        if (typeof (v.optionsids) == 'object') {
          arr = v.optionsids
        } else {
          arr = [v.optionsids]
        }
        list.push({
          "questionid": v.questionid,
          "optionsids": arr,
          "othertext": v.othertext
        })
      })
      let params = {};
      let userid = s.user.userid;
      if (s.survey.isrealname != 0) {
        if(localStorage.getItem('user')){
          params = {
            "info": {
              "inquireid": s.$route.params.id,
              "vuid": s.survey.vuid + '',
            },
            "list": list,
            "user": {
              userid: userid
            }
          }
        } else {
          let miao = 5;
          let interval = setInterval(() => {
            Notify({ type: 'warning', message: '该调查问卷需要实名填写，' + miao + '秒后跳转到登录！', duration: 1000 });
            miao--;
            if (miao == 0) {
              clearInterval(interval);
            }
          }, 1000);
          setTimeout(() => {
            s.$router.push('/login?redirect=' + this.$route.path);
          }, 6000)
          return false;
        }
      } else {
        params = {
          "info": {
            "vuid": s.survey.totalnumbers + '',
            "inquireid": s.$route.params.id
          },
          "list": list,
          "user": {
            userid: ''
          }
        }
      }

      huoDongJuAcions.surveySubmit.http(params).then(data => {
        s.canSubmit = true;
        if (data.getret === 0) {
          Toast(data.msg);
          setTimeout(function () {
            if (window.history.state) {
              s.$router.go(-1)
            } else {
              s.$router.push('/survey?companyid'+s.companyid)
            }
          }, 1000)
        } else {
          Toast(data.msg || '提交出错！');
        }
      }).catch(function (error) {
        console.log(error);
        s.canSubmit = true;
      });
    },
    getformstatus() {
      var s = this;
      var condition = {
        "userid": s.user.userid,
        "productid": s.productid,
        "inquireid": s.$route.params.id
      }
      huoDongJuAcions.getformstatus.http({
        condition
      }).then(data => {
        if (data.getret == 0) {
          s.vuid = data.info && data.info.vuid || 0;
          console.log(s.vuid)
          /*没到结束时间时查看提交情况*/
          // console.log(s.curentime,s.survey.endtime)
          if (s.vuid > 0 && s.curentime < s.survey.endtime) {
            /*禁止输入*/
            s.vuidstatus = true;
            /*已提交调查的情况*/
            s.getUsereport(data.info.vuid);
          }
        } else {
          s.vuid = 0;
        }
      })
    },
    getUsereport(vuid) {
      var s = this;
      var condition = {};
      condition = Object.assign(condition, {
        vuid: vuid,
        page_index: 0,
        page_size: 1000,
        companyid: s.companyid,
        inquireid: s.$route.params.id
      })
      huoDongJuAcions.getUsereport.http({
        condition
      }).then(data => {
        if (data.getret === 0) {
          s.questionList.forEach((v, k) => {
            if (v.questiontype == '0') {
              v.optionsids = data.list[k].coloect_options.optionsids[0] * 1
            } else {
              var arr = []
              data.list[k].coloect_options.optionsids.forEach((l, j) => {
                arr.push(l * 1)
              })
              v.optionsids = arr
            }
            v.othertext = data.list[k].coloect_options.othertext
          })
          s.vuidstatus = true;
        }
      })
    },
    compare(property) {
      return function (a, b) {
        var value1 = a[property];
        var value2 = b[property];
        return value1 - value2;
      }
    }
  },
  destroyed() { }
}
</script>
<style scoped>
button {
  border: none;
  color: #ffffff;
  border-radius: 30px;
  background: -webkit-linear-gradient(#f7514d, #fd704d);
}
button:focus {
  outline: none !important;
}
.van-checkbox-group--horizontal {
  display: block !important;
}
.van-field__body {
  background-color: #f5f5f5 !important;
}
.van-icon {
  font-size: 22px !important;
}
.van-checkbox,
.van-radio {
  align-items: flex-start !important;
}

</style>